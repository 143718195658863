import React, { useEffect, useMemo, useState } from "react";
import { oauth } from "../../../../utils";
import {
  EllipsisIcon,
  PanelledSelect,
  Row,
  UsersIcon,
  colors,
} from "@commonsku/styles";
import { OptionProps, SingleValueProps, components } from "react-select";
import Avatar from "../../../helpers/Avatar";
import { FilterMainComponentProps } from "../Filter";
import { useHasCapabilities } from "../../../../hooks";

interface User {
  user_id: string;
  first_name: string;
  last_name: string;
  active: boolean;
  avatar_url: string;
}

interface Team {
  team_id: string;
  team_name: string;
  active: boolean;
  users: User[];
}

interface UserOption {
  type: "user";
  user: User;
  value: User["user_id"];
  Component: (user: User) => JSX.Element;
}

interface TeamOption {
  type: "team";
  team: Team;
  value: Team["team_id"];
  Component: (team: Team) => JSX.Element;
}

interface CategoryOption {
  type: "category";
  /* value must be unique, e.g. the category's name */
  value: string;
  Component: () => JSX.Element;
  subOptions: UserOption[] | TeamOption[];
}

interface ControlOption {
  type: "control";
  /* value must be unique, e.g. the control's name */
  value: string;
  Component: () => JSX.Element;
}

type Option = UserOption | TeamOption | CategoryOption | ControlOption;

const optionContainerStyles: React.CSSProperties = {
  display: "flex",
  width: "100%",
  maxWidth: "320px",
  alignItems: "center",
};

const optionStyles: React.CSSProperties = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const getOptionSearchLabel = (option: Option) => {
  switch (option.type) {
    case "user":
      return `${option.user.first_name} ${option.user.last_name}`;
    case "team":
      return option.team.team_name;
    default:
      return "";
  }
};

const UserAvatar = (user: User) => (
  <Avatar
    name={`${user.first_name} ${user.last_name}`}
    user_image_paths={user.avatar_url === "" ? {} : { small: user.avatar_url }}
    backgroundColor={colors.teal["main"]}
    style={{
      height: 24,
      width: 24,
      fontSize: "0.75rem",
      paddingRight: 0,
    }}
  />
);

const UserOptionComponent = (user: User) => (
  <div style={optionContainerStyles}>
    <UserAvatar {...user} />
    <div style={optionStyles}>
      {user.first_name} {user.last_name}
    </div>
  </div>
);

const TeamOptionComponent = (team: Team) => (
  <div style={optionContainerStyles}>
    <UsersIcon style={{ marginRight: 8 }} />
    <div style={optionStyles}>{team.team_name}</div>
  </div>
);

const TeamCategoryComponent = () => (
  <Row>
    <UsersIcon style={{ marginRight: 8 }} />
    Select Group
    <EllipsisIcon style={{ marginLeft: "auto" }} />
  </Row>
);

const InactiveUsersCategoryComponent = () => (
  <Row>
    <UsersIcon style={{ marginRight: 8 }} />
    Inactive Users
    <EllipsisIcon style={{ marginLeft: "auto" }} />
  </Row>
);

const AllActiveControlComponent = () => (
  <Row>
    <UsersIcon style={{ marginRight: 8 }} />
    All Active
  </Row>
);

const AllControlComponent = () => (
  <Row>
    <UsersIcon style={{ marginRight: 8 }} />
    All
  </Row>
);

const OptionComponent = ({
  data,
  innerProps,
  innerRef,
  isSelected,
  isFocused,
}: OptionProps<Option>) => {
  let optionComponent: JSX.Element;

  switch (data.type) {
    case "user":
      optionComponent = <data.Component {...data.user} />;
      break;
    case "team":
      optionComponent = <data.Component {...data.team} />;
      break;
    case "category":
      optionComponent = <data.Component />;
      break;
    case "control":
      optionComponent = <data.Component />;
      break;
    default:
      throw new Error("Invalid option type");
  }

  return (
    <div
      ref={innerRef}
      style={{
        padding: "8px",
        cursor: "pointer",
        backgroundColor: isSelected
          ? colors.teal[60]
          : isFocused
            ? "#E1F7FA"
            : undefined,
      }}
      {...innerProps}
    >
      {optionComponent}
    </div>
  );
};

const LabelComponent = (props: SingleValueProps<Option>) => {
  const { data } = props;
  let optionComponent: JSX.Element;

  switch (data.type) {
    case "user":
      optionComponent = <data.Component {...data.user} />;
      break;
    case "team":
      optionComponent = <data.Component {...data.team} />;
      break;
    case "category":
      optionComponent = <data.Component />;
      break;
    case "control":
      optionComponent = <data.Component />;
      break;
    default:
      throw new Error("Invalid option type");
  }

  return (
    <components.SingleValue {...props}>
      {optionComponent}
    </components.SingleValue>
  );
};

const UserSelect = ({
  values,
  onChange,
  placeholder = "Select a Rep",
}: FilterMainComponentProps) => {
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [userMap, setUserMap] = useState<Map<string, User>>();
  const [teamMap, setTeamMap] = useState<Map<string, Team>>();
  const hasUserGroups = useHasCapabilities('HAS-USER-GROUPS');

  useEffect(() => {
    let ignoreFetch = false;

    const fetchUsersAndTeams = async () => {
      try {
        const {
          json: { users, teams },
        } = await oauth("GET", "user/sales-rep-summary", {});

        if (ignoreFetch) return;

        const userMap = new Map();
        const teamMap = new Map();

        users
          .filter(
            (user: User) => user.first_name !== "" && user.last_name !== "",
          )
          .forEach(
            (
              user: Omit<User, "active"> & {
                active: 0 | 1;
              },
            ) => {
              userMap.set(user.user_id, {
                ...user,
                active: Boolean(user.active),
              });
            },
          );

        teams.forEach(
          (
            team: Omit<Team, "users" | "active"> & {
              user_ids: string;
              active: 0 | 1;
            },
          ) => {
            teamMap.set(team.team_id, {
              ...team,
              active: Boolean(team.active),
              users: JSON.parse(team.user_ids).map((user_id) =>
                userMap.get(user_id),
              ),
            });
          },
        );

        setUserMap(userMap);
        setTeamMap(teamMap);
      } catch (e) {
        setError(true);
      } finally {
        setFetching(false);
      }

      return () => (ignoreFetch = true);
    };
    fetchUsersAndTeams();
  }, []);

  const userOptions: UserOption[] = useMemo(() => {
    if (userMap == null) return [];

    return Array.from(userMap.values()).map((user) => ({
      type: "user",
      user: user,
      value: user.user_id,
      Component: UserOptionComponent,
    }));
  }, [userMap]);

  const teamOptions: TeamOption[] = useMemo(() => {
    if (teamMap == null) return [];

    return Array.from(teamMap.values()).map((team) => ({
      type: "team",
      team: team,
      value: team.team_id,
      Component: TeamOptionComponent,
    }));
  }, [teamMap]);

  const teamCategoryOption: CategoryOption = useMemo(
    () => ({
      type: "category",
      value: "category-teams",
      Component: TeamCategoryComponent,
      subOptions: teamOptions,
    }),
    [teamOptions],
  );

  const inactiveCategoryOption: CategoryOption = useMemo(
    () => ({
      type: "category",
      value: "category-inactive-users",
      Component: InactiveUsersCategoryComponent,
      subOptions: userOptions.filter((option) => option.user.active === false),
    }),
    [userOptions],
  );

  const allActiveOption: ControlOption = useMemo(
    () => ({
      type: "control",
      value: "",
      Component: AllActiveControlComponent,
    }),
    [],
  );

  const allOption: ControlOption = useMemo(
    () => ({
      type: "control",
      value: "on",
      Component: AllControlComponent,
    }),
    [],
  );

  const options: Option[] = useMemo(() => {
    const options: Option[] = [];

    const activeUserOptions = userOptions.filter(
      (option) => option.user.active === true,
    );

    options.push(allActiveOption, allOption, ...activeUserOptions);

    if (hasUserGroups && teamOptions.length !== 0) {
      options.push(teamCategoryOption);
    }

    if (userOptions.length !== activeUserOptions.length) {
      options.push(inactiveCategoryOption);
    }

    return options;
  }, [
    userOptions,
    allActiveOption,
    allOption,
    teamOptions.length,
    teamCategoryOption,
    inactiveCategoryOption,
  ]);

  const onSelect = (selected: Option | Option[] | null) => {
    const value = Array.isArray(selected)
      ? selected.map((v) => v.value)
      : selected?.value;

    if (selected === null || value === "") {
      onChange(["", "", ""]);
      return;
    }
    if (Array.isArray(selected) && selected.length === 0) {
      onChange(["", [], ""]);
      return;
    }

    const firstSelected = Array.isArray(selected) ? selected[0] : selected;
    switch (firstSelected.type) {
      case "team":
        onChange(["TEAMS", value, ""]);
        break;
      case "user":
        onChange([value, "", ""]);
        break;
      case "control":
        switch (firstSelected.value) {
          case "on":
            onChange(["", "", "on"]);
            break;
          default:
            break;
        }
    }
  };

  const inputValue = useMemo(() => {
    if (Array.isArray(values) ? values.every((v) => v === "") : values === "") {
      return allActiveOption;
    }

    // @ts-ignore
    const [user, team, includeInactive] = values;

    if (includeInactive === "on") {
      return allOption;
    }

    if (user !== "TEAMS") {
      if (userMap && userMap.has(user)) {
        return userOptions.filter((o) => o.value === user);
      }
    } else {
      if (teamMap && teamMap.has(team)) {
        return teamOptions.filter((o) => o.value === team);
      }
    }
  }, [allActiveOption, teamMap, teamOptions, userMap, userOptions, values]);

  const isClearable = useMemo<boolean>(
    () =>
      Array.isArray(values) ? values.some((v) => v !== "") : values !== "",
    [values],
  );

  return (
    <PanelledSelect
      value={inputValue}
      isClearable={isClearable}
      onChange={onSelect}
      options={error ? undefined : options}
      error={error}
      placeholder={
        error ? "Error loading Reps" : fetching ? "Loading..." : placeholder
      }
      components={{
        Option: OptionComponent,
        SingleValue: LabelComponent,
      }}
      subMenuProps={{
        components: { Option: OptionComponent },
      }}
      containerStyles={{ width: "100%" }}
      getOptionLabel={getOptionSearchLabel}
      isMulti={false}
      singleValueStyles={{
        display: "flex",
        alignItems: "center",
      }}
    />
  );
};

export default UserSelect;
